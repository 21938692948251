import { inject, Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { defer, from, Observable } from 'rxjs';

// For some reason it is not possible to import the interface
// from @ionic/core
// import { OverlayEventDetail } from '@ionic/core';
export interface OverlayEventDetail<T = any> {
  data?: T;
  role?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private alertCtrl = inject(AlertController);

  public showConfirmAlert$(
    title: string,
    message: string,
    okButtonText: string = 'OK',
    cancelButtonText: string = 'Cancel',
    okAction?: () => void,
    cancelAction?: () => void
  ): Observable<OverlayEventDetail<string>> {
    return defer(() => from(
      this.showConfirmAlert(
        title,
        message,
        okButtonText,
        cancelButtonText,
        okAction,
        cancelAction
      )
    ));
  }

  public async showConfirmAlert(
    title: string,
    message: string,
    okButtonText: string = 'OK',
    cancelButtonText: string = 'Cancel',
    okAction?: () => void,
    cancelAction?: () => void
  ): Promise<OverlayEventDetail<string>> {
    const alertPromise: HTMLIonAlertElement = await this.alertCtrl.create({
      header: title,
      message,
      buttons: [
        {
          role: 'cancel',
          text: cancelButtonText ? cancelButtonText : 'Cancel',
          handler: (): void => cancelAction?.()
        },
        {
          role: 'ok',
          text: okButtonText ? okButtonText : 'OK',
          handler: (): void => okAction?.()
        }
      ]
    });

    await alertPromise.present();
    return await alertPromise.onDidDismiss();
  }

  public showSimpleAlert$(
    title: string,
    message: string,
    okButtonText: string = 'OK'
  ): Observable<void> {
    return defer(() => from(
      this.showSimpleAlert(
        title,
        message,
        okButtonText
      )
    ));
  }

  public async showSimpleAlert(
    title: string,
    message: string,
    okButtonText: string = 'OK'
  ): Promise<void> {
    const alertPromise: HTMLIonAlertElement = await this.alertCtrl.create({
      header: title,
      message,
      buttons: [
        {
          role: 'ok',
          text: okButtonText ? okButtonText : 'OK'
        }
      ]
    });

    await alertPromise.present();
  }

  public showErrorAlert$(
    message: string,
    title: string = 'Error',
  ): Observable<void> {
    return defer(() => from(
      this.showErrorAlert(
        message,
        title
      )
    ));
  }

  public async showErrorAlert(
    message: string,
    title: string = 'Error',
  ): Promise<void> {
    const alertPromise: HTMLIonAlertElement = await this.alertCtrl.create({
      header: title,
      message
    });

    await alertPromise.present();
  }
}
